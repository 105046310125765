import { HTTPError } from 'ky';

export interface ApiResponse<T> {
    data: T;
    error: APIError;
    meta: unknown;
}

export interface ValidationError {
    type: string;
    message: string;
    params: any;
}

export interface APIError {
    code: string;
    message: string;
    details?: any;
}

export class APIErrorWrapper {
    public status: number;
    public status_text: string;
    public message: string;
    public code: string;
    public details?: string;
    public isJSON: boolean;

    constructor(status: number, status_text: string, message: string, code: string, details?: string, isJSON?: boolean) {
        this.status = status;
        this.status_text = status_text;
        this.message = message;
        this.code = code;
        this.details = details;
        this.isJSON = isJSON ?? false;
    }
}