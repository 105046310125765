<template>
  <div style='overflow-x: clip; overflow-y: hidden; min-height: 100vh;'>
    <v-alert class='text-center' color='error' v-if='isAPIDown'>
      {{ t("API_IS_DOWN") }}
    </v-alert>
    <v-dialog
      v-show='error !== null'
      :model-value='openErrorDialog'
      @update:model-value='(val) => openErrorDialog = val'
      width='auto'
      :close-on-content-click='false'
    >
      <v-card class='pa-2' color='red-lighten-2' :title='t("ERROR_DIALOG_TITLE")' :subtitle='t("ERROR_DIALOG_SUBTITLE")'>
        <v-card-text class='bg-black rounded ma-2'>
          <div class='pa-2'>
            <div v-if='error instanceof APIErrorWrapper'>
              <p>HTTP status: {{ error.status_text }} ({{ error.status }})</p><br>
              <p>Code: {{ error.code }}</p>
              <p>Message: {{ error.message }}</p>
              <p v-if='!!error.details'><br><br>Details:
                <span v-if='error.isJSON && !!error.details'><br><br>
                {{ JSON.stringify(error?.details, null, 2) }}
              </span>
                <span v-else>{{ error?.details }}</span>
              </p>
            </div>
            <div v-else>
              <p>{{ error?.message }}</p><br>
              <p>{{ (error as Error)?.stack }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <router-view v-slot="{ Component }">
      <transition name="layout" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  </div>
</template>
<script setup lang='ts'>
import AOS from 'aos';
import { onMounted, provide, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { APIErrorWrapper } from './types/api';

const { t } = useI18n();

const error = ref<APIErrorWrapper | Error | null>(null);
const openErrorDialog = ref(false);

const showError = (err: APIErrorWrapper | Error) => {
  error.value = err;
  openErrorDialog.value = true;
}

provide("errorDialog", showError);

const isAPIDown = ref(false);
const setAPIDown = (value: boolean) => isAPIDown.value = value;

provide("isAPIDown", {
  isDown: isAPIDown,
  setDown: setAPIDown
});

onMounted(() => {
  setTimeout(() => {
    AOS.refresh();
  }, 500);
});

</script>

<style lang='scss'>
@import '@/styles/main';
</style>
