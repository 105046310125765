export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/buildhome/repo/src/pages/index.vue'),
    children: [
      {
        path: '',
        name: '//',
        component: () => import('/opt/buildhome/repo/src/pages/index/index.vue'),
        /* no children */
      },
      {
        path: ':all(.*)',
        name: '//[...all]',
        component: () => import('/opt/buildhome/repo/src/pages/index/[...all].vue'),
        /* no children */
      },
      {
        path: 'premium',
        name: '//premium',
        component: () => import('/opt/buildhome/repo/src/pages/index/premium.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/account',
    name: '/account',
    component: () => import('/opt/buildhome/repo/src/pages/account.vue'),
    children: [
      {
        path: '',
        name: '/account/',
        component: () => import('/opt/buildhome/repo/src/pages/account/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/manage',
    name: '/manage',
    component: () => import('/opt/buildhome/repo/src/pages/manage.vue'),
    children: [
      {
        path: '',
        name: '/manage/',
        component: () => import('/opt/buildhome/repo/src/pages/manage/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/manage/[id]',
        component: () => import('/opt/buildhome/repo/src/pages/manage/[id].vue'),
        children: [
          {
            path: '',
            name: '/manage/[id]/',
            component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'perms',
            name: '/manage/[id]/perms',
            component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/perms.vue'),
            children: [
              {
                path: '',
                name: '/manage/[id]/perms/',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/perms/index.vue'),
                /* no children */
              },
              {
                path: ':role',
                /* internal name: '/manage/[id]/perms/[role]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/manage/[id]/perms/[role]/',
                    component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/perms/[role]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'role-menu',
            name: '/manage/[id]/role-menu',
            component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/role-menu.vue'),
            /* no children */
          },
          {
            path: 'settings',
            /* internal name: '/manage/[id]/settings' */
            /* no component */
            children: [
              {
                path: ':all(.*)',
                name: '/manage/[id]/settings/[...all]',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/[...all].vue'),
                /* no children */
              },
              {
                path: 'auto-messages',
                name: '/manage/[id]/settings/auto-messages',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/auto-messages.vue'),
                /* no children */
              },
              {
                path: 'joinleave',
                name: '/manage/[id]/settings/joinleave',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/joinleave.vue'),
                /* no children */
              },
              {
                path: 'levels',
                name: '/manage/[id]/settings/levels',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/levels.vue'),
                /* no children */
              },
              {
                path: 'media-only',
                name: '/manage/[id]/settings/media-only',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/media-only.vue'),
                /* no children */
              },
              {
                path: 'notifications',
                name: '/manage/[id]/settings/notifications',
                component: () => import('/opt/buildhome/repo/src/pages/manage/[id]/settings/notifications.vue'),
                /* no children */
              }
            ],
          }
        ],
        meta: {
          "requiresGuildAccess": true
        }
      }
    ],
  },
  {
    path: '/verify',
    name: '/verify',
    component: () => import('/opt/buildhome/repo/src/pages/verify.vue'),
    children: [
      {
        path: ':guild_id-:user_id',
        /* internal name: '/verify/[guild_id]-[user_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/verify/[guild_id]-[user_id]/',
            component: () => import('/opt/buildhome/repo/src/pages/verify/[guild_id]-[user_id]/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]
