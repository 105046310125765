import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import router from './router';
import aos from './plugins/aos';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from "@tanstack/vue-query";
import VueTransitions from '@morev/vue-transitions';

if (!import.meta.env.VITE_CLIENT_ID) throw "VITE_CLIENT_ID variable is empty";

const pinia = createPinia();

createApp(App)
    .use(vuetify)
    .use(i18n)
    .use(router)
    .use(aos)
    .use(pinia)
    .use(VueQueryPlugin)
    .use(VueTransitions)
    .mount('#app');
