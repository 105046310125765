import { createI18n } from 'vue-i18n';
import pl from '@/locales/pl.json';
import en from '@/locales/en.json';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

console.log(cookies.get('lang'));

export default createI18n({
  legacy: false,
  locale: cookies.get('lang') || navigator.language.split('-')[0] || 'en',
  fallbackLocale: 'en',
  messages: { pl, en },
});
