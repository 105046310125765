// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';


export default createVuetify({
    theme: {
        defaultTheme: 'myTheme',
        themes: {
            myTheme: {
                colors: {
                    primary: '#10a87c',
                    secondary: '#185a61',
                    accent: '#FF4081',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00',
                    background: '#023f46',
                    surface: '#1f8d7c',
                },
                dark: true,
                variables: {},
            },
        },
    }
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
