// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createRouter, createWebHistory } from 'vue-router/auto';
import * as API from "@/services/boomAPI";

const router = createRouter({
    history: createWebHistory(),
    strict: true,
});

/*router.beforeEach((to, from) => {
    if (to?.meta?.requiresGuildAccess) {
        return API.checkAccess(to?.params?.id as string).then(() => true).catch(() => {
            return { path: "/manage" }
        });
    }
    return true;
});*/

router.beforeEach((to, from) => {
    if (to.fullPath.endsWith('/') && to.fullPath != '/') {
        return to.fullPath.slice(0, -1);
    }
});

export default router;


// import { createRouter, createWebHistory } from 'vue-router';
// import DashLayout from './layouts/dash.vue';
// import DashHome from '@/pages/dashboard/_perms.vue';
// import DefaultLayout from './layouts/_perms.vue';
// import Home from './pages/_perms.vue';
// import NotFound from './pages/[...all].vue';
//
// const routes = [
//   {
//     path: '',
//     component: DefaultLayout,
//     children: [
//       { path: '', component: Home },
//       { path: ':catchAll(.*)', component: NotFound }
//     ],
//   },
//   {
//     path: '/dash',
//     component: DashLayout,
//     children: [{ path: '', component: DashHome }],
//   },
// ];
//
// const router = createRouter({
//   // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//   history: createWebHistory(),
//   routes, // short for `routes: routes`
// });
//
// export default router;
